<template>
  <div class="feature-list">
      <h1 v-if="$route.name === 'Features'" v-html="$t('featureList.heading')"></h1>

      <h2 v-else v-html="$t('featureList.heading')"></h2>

      <ul>
          <li
            v-for="item in list"
            :key="item.name"
            @click="toggleFeature(item.name)"
            class="feature-list__item"
            :class="[item.name, { 'feature-list__item--not-shown': !item.shown }]"
          >
            <p v-html="$t(`featureList.${item.name}.title`)"></p>

            <svg-icon :icon="item.icon" />

            <transition name="smooth">
                <p
                    v-if="item.shown"
                    v-html="$t(`featureList.${item.name}.description`)"
                    class="feature-list__description"
                >
                </p>
            </transition>
          </li>
      </ul>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'

export default {
    name: 'FeatureList',
    components: {
        SvgIcon,
    },
    data() {
        return {
            list: [
                {
                    name: 'navigationMenu',
                    icon: 'feature-list/menu',
                    shown: false,
                },
                {
                    name: 'userAdminLogin',
                    icon: 'feature-list/adminuser',
                    shown: false,
                },
                {
                    name: 'gallery',
                    icon: 'feature-list/gallery',
                    shown: false,
                },
                {
                    name: 'contactForm',
                    icon: 'feature-list/contactform',
                    shown: false,
                },
                {
                    name: 'googleMaps',
                    icon: 'feature-list/maps',
                    shown: false,
                },
                {
                    name: 'googleAnalytics',
                    icon: 'feature-list/analytics',
                    shown: false,
                },
                {
                    name: 'languages',
                    icon: 'feature-list/languages',
                    shown: false,
                },
                {
                    name: 'searchInput',
                    icon: 'feature-list/search',
                    shown: false,
                },
                {
                    name: 'responsiveDesign',
                    icon: 'feature-list/responsive',
                    shown: false,
                },
                {
                    name: 'lazyLoad',
                    icon: 'feature-list/lazyload',
                    shown: false,
                },
                {
                    name: 'hostingDomain',
                    icon: 'feature-list/hostingdomain',
                    shown: false,
                },
                {
                    name: 'database',
                    icon: 'feature-list/database',
                    shown: false,
                },
                {
                    name: 'mailSetup',
                    icon: 'feature-list/mailsetup',
                    shown: false,
                },
                {
                    name: 'seoOptimization',
                    icon: 'feature-list/seo',
                    shown: false,
                },
                {
                    name: 'ssl',
                    icon: 'feature-list/ssl',
                    shown: false,
                },
                {
                    name: 'pwa',
                    icon: 'feature-list/pwa',
                    shown: false,
                },
                {
                    name: 'themes',
                    icon: 'feature-list/themes',
                    shown: false,
                },
                {
                    name: 'api',
                    icon: 'feature-list/api',
                    shown: false,
                },
                {
                    name: 'validation',
                    icon: 'feature-list/validation',
                    shown: false,
                },
                {
                    name: 'animation',
                    icon: 'feature-list/animation',
                    shown: false,
                },
                {
                    name: 'filters',
                    icon: 'feature-list/filters',
                    shown: false,
                },
                {
                    name: 'customizedFeatures',
                    icon: 'feature-list/customizedfeatures',
                    shown: false,
                },
                {
                    name: 'favIcon',
                    icon: 'feature-list/favicon',
                    shown: false,
                },
            ]
        }
    },
    computed: {
        shownItems() {
            return this.list.filter(item => item.shown)
        },
    },
    methods: {
        toggleFeature(item) {
            // Close the other shown feature
            if (this.shownItems.filter(i => i.name === item).length > 0) {
                this.list.find(feature => feature.name === item).shown = false
            } else {
                this.list.find(feature => feature.name === item).shown = true
            }
        },
    },
    mounted() {

    },
}
</script>

<style lang="scss">
.feature-list {
    h1 {
        padding-top: 12px;
        color: white;
        font-size: 30px;
        font-weight: 500;

        @media (min-width: 1024px) {
            margin-bottom: 36px;
            font-size: 40px;
        }
    }

    ul {
        @media (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &::after {
                content: '';
                flex-basis: 30%;
            }
        }
    }

    &__item {
        display: flex;
        width: 100%;
        margin-bottom: 24px;
        flex-direction: column;
        border-radius: 10px;
        background-color: white;
        box-shadow: #00000017 0 1px 6px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        transition: transform 3s ease-in-out;
        cursor: pointer;

        @media (min-width: 768px) {
            width: auto;
            width: 48%;
        }

        @media (min-width: 1300px) {
            width: 30%;
        }

        &--not-shown {
            height: 100px;
        }

        .svg-container {
            margin: 0 auto 16px;
            font-size: 32px;
        }
    }

    &__description {
        display: block;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        transition: transform 3s ease-in-out;
        transform-origin: top;

        @media (min-width: 375px) {
            font-size: 16px;
        }
    }
}

.smooth-enter-active, .smooth-leave-active {
    transition: all .4s ease-in-out;
    overflow: hidden;
}

.smooth-enter, .smooth-leave-to {
    height: 100%;
    transform: scaleY(0);
    transition-duration: 5s;
}
</style>
