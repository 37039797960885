<template>
    <div class="features">
        <feature-list/>
    </div>
</template>

<script>
import FeatureList from '@/components/FeatureList'

export default {
    name: 'Features',
    components: {
        FeatureList,
    },
}
</script>

<style lang="scss">
.features {
    margin: 0 -18px 0;
    background-color: #616062;

    .feature-list {
        padding: 60px 18px;

        h2 {
            color: white;
        }

        @media (min-width: 768px) {
            padding: 60px 24px 48px;
        }

        @media (min-width: 1024px) {
            padding: 78px 48px 48px;
        }

        @media (min-width: 1300px) {
            padding: 132px 66px 66px;
        }
    }
}
</style>
